// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arena-index-jsx": () => import("./../src/pages/arena/index.jsx" /* webpackChunkName: "component---src-pages-arena-index-jsx" */),
  "component---src-pages-blog-page-jsx": () => import("./../src/pages/blogPage.jsx" /* webpackChunkName: "component---src-pages-blog-page-jsx" */),
  "component---src-pages-desert-index-jsx": () => import("./../src/pages/desert/index.jsx" /* webpackChunkName: "component---src-pages-desert-index-jsx" */),
  "component---src-pages-devlog-index-jsx": () => import("./../src/pages/devlog/index.jsx" /* webpackChunkName: "component---src-pages-devlog-index-jsx" */),
  "component---src-pages-downhill-index-jsx": () => import("./../src/pages/downhill/index.jsx" /* webpackChunkName: "component---src-pages-downhill-index-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-output-index-jsx": () => import("./../src/pages/output/index.jsx" /* webpackChunkName: "component---src-pages-output-index-jsx" */),
  "component---src-pages-presskit-index-jsx": () => import("./../src/pages/presskit/index.jsx" /* webpackChunkName: "component---src-pages-presskit-index-jsx" */),
  "component---src-pages-surfize-index-jsx": () => import("./../src/pages/surfize/index.jsx" /* webpackChunkName: "component---src-pages-surfize-index-jsx" */)
}

